#check {
  opacity: 0;
  display: none;
}

label {
  position: relative;
  width: 100%;
	display: block;
	cursor: pointer;
}

#check+label:before,
#check+label:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
}

#check+label:before {
  width: 20px;
  height: 20px;
  // background: color(gradient-main);
  z-index: 1;
  border-style: solid;
  border-width: 1px;
  border-image: color(gradient-main) 1;
}

#check+label:after {
  margin: 1px;
  width: 10px;
  height: 10px;
  border-width: 0 0 18px 18px;
  border-style: solid;
  border-color: color(dark-light);
}

#check:checked+label:after {
  // margin: 1px;
  // width: 10px;
  // height: 10px;
  // border-width: 0 0 18px 18px;
  // border-style: solid;
  // border-color: black;

  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 6px;
  width: 6px;
  height: 14px;
  border: solid red;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}