$violet: #8E0A28;
$blue: #E50050;

body { background: #000; }

.orb {
    animation:spin 4s linear infinite;
    width: 300px;
    height: 300px;
    margin: auto;
    margin-top: 140px;
    border-radius: 50%;
    box-shadow:
        inset 0 0 50px yellow,
        inset 20px 0 60px $violet,
        inset -20px 0 60px $blue,
        inset 20px 0 300px $violet,
        inset -20px 0 300px $blue,
          0 0 50px #fff,
          -10px 0 60px $violet,
          10px 0 60px $blue;
}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }