.noti-wrapper{
position: fixed;
top:50px;
margin-top: 40px;
z-index: 9999;
min-width: 260px;
padding:20px;
text-align: center;
border-radius: 0.5rem;
// box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}