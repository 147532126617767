.cart-page {

    .first-row__wrapper{
     justify-content: center;
margin-left: auto;
margin-right: auto;

     .center-table{
        width: 100%;
     }

   @include size(min, phone, xlarge) {
        width: 450px;
    }
  
    }

    .orient-cols{
        :first-child{
            margin-right: 0 !important;
        }
    }
    .container {
            max-width: unset !important;
    }


    .item__kinds-wrapper {
        // grid-column-start: 1 !important;
        // margin-top: 20px;
    }


    .discount-code-col {
        .discount-input-wrapper {
            margin-left: auto;
            width: 75%;

            @include size(max, phone, xlarge) {
                width: 80%;
                font-size: 12px;
            }
        }
    }


    .page-wrapper {
        flex-direction: row;
        display: flex;
        justify-content: center;
    }

    .container-main-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .container-second-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        width: 800px;
        height: 455px;

    }

    .container-img-bg {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
        background-image: url("../../../../_clientPage/assets/images/backgrounds/container.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
        max-width: 800px;

        @include size(max, tablet, xxmedium) {
            background-size: contain;
        }
    }

    .container-img-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        width: 100%;
        z-index: -1;
        max-width: 800px;
        left: 14%;
        top: 28.5%;
        width: 70%;
        z-index: -1;

        @include size(min, phone, small) {
            top: 40.5%;
        }

        @include size(min, phone, medium) {
            top: 42%;
        }


        @include size(min, phone, xlarge) {
            top: 37%;
        }

        @include size(min, tablet, xsmall) {
            top: 29%;
        }

        @include size(min, tablet, medium) {
            top: 33%;
        }


        @include size(min, tablet, xxmedium) {
            top: 26%;
        }

        // display: grid;
        // grid-template-rows: 50% 50%;


        // grid-template-columns: 25% 25% 25% 25%;


        svg {
            height: 105px;
        }

        &.column-rev {
            flex-direction: column-reverse;
        }


        #firstRow,
        #secondRow {
            height: 105px;
            display: flex;


            @include size(min, phone, small) {
                height: 40px;
            }

            @include size(min, phone, xlarge) {
                height: 60px;
            }

            @include size(min, tablet, xsmall) {
                height: 85px;
            }

            @include size(min, tablet, xxmedium) {
                height: 105px;
            }
        }
    }

    .page-wrapper {
        @include size(max, screen, xsmall) {
            flex-direction: column;

            .container-main-wrapper {
                width: 100%;
            }

            .cart-box-main-wrapper {
                width: 100%;
            }
        }
    }

    .divItem {
        // width: 125px;
        // height: 105px;
        display: flex;
        flex-grow: 0;
        // flex-basis: calc(100% / 4);
        width: calc(80% / 4);

        background-image: url("../../../../_clientPage/assets/images/graphs/box.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

    }


    .page-content-wrapper__two-rows-sp__around {
        padding-top: 0;
        justify-content: center;
    }

    .active {
        fill: url(#paint0_linear_315_3417);
    }
}