.pending-order__page {


    @include size(max, screen, small) {
        .page-content {
            padding-top: 200px;
        }
    }
  
    
    @include size(max, screen, small) {
        .page-content-wrapper__two-rows-sp__around {
            // padding-top: 300px;
            padding-bottom: 0;
        }
    }

    .table-type__line {
        width: 120%;
        top: -12px;
        right: 75px;
    }

    .boxed--table-type {
        position: unset;
    }


    .first-row__wrapper {
        justify-content: center;
        display: flex;

        .box-wrapper {
            position: relative;
        }
    }





    .center-table {
        width: 600px;

        .col {

            &.messages-class {
                top: 50%;
                transform: translateY(-50%);
                position: absolute;

                // h3 {
                //     color: red;
                //     font-weight: 500;
                //     font-size: 32px;
                // }
            }

        }
    }

    .bottom-side {
        .button-container {
            button {
                padding: 20px 25px !important;


                &.submit-btn {
                    padding: 20px 20px !important;
                }
            }

        }
    }
}