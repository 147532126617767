.best-selling-items-page {


    .wout__second-row {
        max-width: 450px;

        @include size(min, screen, xxsmall) {
            max-width: 1170px;
        }
    }

    .global-button{
       

        @include size(max, phone, large) {
            padding: 20px 30px !important;
        }
    }
    .titles-wrapper{
        .page-title{
            @include size(max, phone, large) {
                font-size: 35px;
            }
        }
    }
 
    .page-content {
        @include size(min, screen, small) {
            max-width: unset;
        }
    }

    .item-wrapper {
        max-width: 350px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-width: 200px;

        margin-left: auto;
        margin-right: auto;

        &:not(:last-of-type) {
            margin-right: 70px;
        }





        .item-img {
            margin-bottom: 30px;
            margin-right: auto;
            margin-left: auto;
        }
    }

    .webshop-section__wrapper {



        @include size(min, tablet, large) {
            width: 500px;
        }

        @include size(min, tablet, xlarge) {
            .page-subtitle {
                margin-top: 0;
            }
        }

        h1 {
            color: red;
        }

        .w-title-wrapper {
            position: relative;
        }
    }


    .slide-cards {

        .second-row__wrapper {
            flex-direction: row;

            @include size(max, screen, small) {
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .webshop-section__wrapper {
                    .w-title-wrapper {
                        text-align: center;
                    }
                }

                .page-subtitle {
                    margin-left: 0 !important;

                    margin-top: 10px;
                    margin-bottom: 20px;

                }

                .browse-items {
                    margin-left: 0;
                }
            }
        }

        .button-wrapper {
            display: flex;
            flex-direction: row;

            div:first-child {
                margin-right: 15px;
            }
        }
    }


    .titles-wrapper {
        &.route-wrapper {
            @include size(max, screen, small) {
                margin-top: 0;
            }
        }
    }


}