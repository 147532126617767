.empty-states-wrapper{
    display: flex;
    justify-content: center;
    img{
        width: 200px;
    }
}

.empty-state-description{
    text-align: center;
    font-size: 30px;
    color: #231f20;
    opacity: .8;
}