
.affiliate-wrapper{

    .affiliate-span{
        font-size: 12px;
        opacity: .6;
    }

    .affiliate-name{
        font-size: 12px;
    }
   
}

.affiliate-benefits-btn{
background: transparent;

}
