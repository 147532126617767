.previous-order__page {
    .kind-item {
        grid-template-columns: 50% 50% !important;

        .result__price {
            display: flex;
            justify-content: flex-start;

            @include size(min, phone, large) {
                justify-content: flex-end;
            }
        }
    }

    .boxed__footer {
        .second-col {
            text-align: end;
        }
    }


    @include size(max, tablet, small) {
        .grid-wrapper {
            .row {
                div:not(.heade-title) {
                    text-align: end;
                }
            }
        }
    }
  
}