$breakpoints:(
    phone:(
        small: 320px,
        medium: 360px,
        large: 414px,
        xlarge: 450px,
        huge: 480px,
        default: 321px
    ),
    
    tablet:(
        small: 600px,
        xsmall: 650px,
        medium: 768px,
        xmedium: 865px,
        xxmedium: 890px,
        large:992px,
        xlarge: 1024px,
        xxlarge: 1100px,
        default: 768px
    ),
    
    screen: (
        small: 1200px,
        xsmall: 1300px,
        xxsmall: 1400px,
        medium: 1440px,
        large: 1600px,
        hd: 1920px,
        default: 1600px
    ),
);

@mixin size($rule, $device, $screen-size){   
    $brkpoint: map-get(map-get($breakpoints, $device), $screen-size);

    @if $rule == min{
        @media(min-width: $brkpoint){
            @content;
        }
    } @else if $rule == max{
        @media(max-width: $brkpoint){
            @content;
        }
    }
}