.success-pay-page {


    .page-content {
        max-width: unset;
    }

    .page-content-wrapper__two-rows-sp__around {
        @include size(max, screen, small) {
            padding-top: 0;
        }
    }

    .ty-wrapper {
        display: flex;
        justify-content: center;
        position: relative;

        @include size(max, phone, xlarge) {
            flex-direction: column;
        }

        .bot-wrapper {
            display: flex;
            align-items: center;

            img {
                height: 140px;
       
                @include size(max, phone, xlarge) {
                    height: 180px;
                    margin-bottom: 20px;
                }
            }

            @include size(min, phone, xlarge) {
                margin-right: 20px;
            }
        }

        h1 {
            line-height: 60px;
            color: red;


         
            @include size(max, phone, xlarge) {
                font-size: 50px;               
            }
        }

        h3 {
            color: color(dark-light-s);
            margin-bottom: 20px;
            margin-top: 10px;

            @include size(max, phone, xlarge) {
                margin-bottom: 30px;
                margin-top: 30px;
                font-size: 18px;
            }


        }

    }


    @include size(max, phone, xlarge) {

        h3,
        h1 {
            text-align: center;
        }

        .bot-wrapper {
            justify-content: center;
        }

        .button-container {
            display: flex;
            justify-content: center;
        }

    }

}