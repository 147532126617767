.shipping-page{
    .bottom-side{
        margin-top: 40px;
    }

    .page-content{
        max-width: unset;

        @include size(max,screen,small){
            padding-top: 350px;
          }
    }

    }

