// IMPORT
@import url('https://fonts.googleapis.com/css?family=Muli:300,400,700,900');

// VARIABLES 
// $canvasBg: #f7f7f7; Grey
$canvasBg: #f1f3f9; // Bright #d5dbed
$textC: #333333;

$col1: #ff8c69;
$col2: #ff696c;
$col3: #1462ff;
$col4: #f0f1f5;

$col3s: #4a74c9;
$col3f: #9cbcff;

$svgC: rgba(#929cd0, .8);
// $shadowC: #d1d4e1;
$shadowC: #d5dbed;
$shadowDef: 0 3px 20px -3px $shadowC;
$gradient-main: color(gradient-main);

// PLACEHOLDERS
%no-selection {
  // No selection
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}



// SVGs
#dribbble {
  position: fixed;
  display: block;
  right: 70px;
  bottom: 16px;

  svg {
    display: block;
    width: 76px;
    height: 24px;
    fill: $svgC;
  }
}

#twitter {
  position: fixed;
  display: block;
  right: 25px;
  bottom: 11px;

  svg {
    width: 24px;
    height: 24px;
    fill: $svgC;
  }
}

// NEW CODE HERE
$sz: 20px;
$fbC: $col3f;

%feedback {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $sz*2;
  height: $sz*2;
  border: 2px solid transparent;
  border-radius: 50%;
  position: relative;
}


.buttons-bar {

  position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  top: 0;
  right: 0;
  left: 0;

  .wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 150px;
    background: transparent;
    position: relative;
    border-radius: 20px;
    // box-shadow: $shadowDef;
  }

  button {
    background: transparent;
  }

  // Button
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 30px;
    background: rgba($col2, 0);
    border-radius: 15px;
    position: relative;
    box-shadow: 0 6px 30px -10px rgba($col3s, 1);
    transition: box-shadow 1299 ease;

    .button-set {
      width: 100px;
      height: 30px;
      background: color(gradient-main);
      border-radius: 15px;
      position: relative;
      left: 0;
      overflow: hidden;

      // z-index: 2;
      &:before {
        content: '';
        width: 50px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 8px;
        opacity: .5;
        box-sizing: border-box;
        border-right: 2px solid white;
      }
    }

    .button-feedback {
      width: 200px;
      height: 60px;
      background: rgba(red, 1);
      border-radius: 15px;
      position: absolute;
      left: 0;
      top: 0;
      // z-index: 1;
    }
  }

  .btn-shadow {
    animation: btnShadow 1000ms ease forwards;
  }

  @keyframes btnShadow {

    0%,
    100% {
      box-shadow: 0 6px 30px -10px red;
    }

    50% {
      box-shadow: 0 6px 30px -10px rgba(255, 255, 254, .5);
    }
  }

  .wave-engaged {
    animation: engage 1s ease;
  }

  @keyframes engage {
    0% {
      opacity: 0;
      background: lighten(red, 0%);
      width: $sz;
      height: $sz;
      top: 0;
      left: 0;
    }

    10% {
      opacity: 1;
      background: lighten(red, 3%);
    }

    50% {
      opacity: 1;
      background: lighten(red, 10%);
    }

    80% {
      width: $sz*20;
      height: $sz*20;
      top: -200px;
      left: -200px;
      opacity: .1;
      background: lighten(red, 10%);
    }

    100% {
      opacity: 0;
      width: $sz*20;
      height: $sz*20;
      top: -200px;
      left: -200px;
    }
  }

  // Feedback
  .feedback {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $sz*3;
    height: $sz*3;
    // border: 2px solid $col3f;
    box-sizing: border-box;
    border: 2px solid transparent;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .feedback-wv-1 {
    @extend %feedback;
    position: relative;
    width: 60px;
    height: 60px;
  }

  .fb-wv-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;

    opacity: 1;
    // width: $sz/3;
    // height: $sz/3;
    // border: 10px solid blue;
    // border: 1px solid rgba($fbC, .0);
    // animation: fb1 1399ms ease infinite;
  }

  @keyframes fb1 {
    0% {
      opacity: .8;
      width: 0;
      height: 0;
      border: 1px solid rgba(red, 1);
    }

    25% {
      opacity: .8;
      width: 0;
      height: 0;
      border: 20px solid rgba(red, 1);
    }

    50% {
      opacity: 1;
      border: 1px solid rgba(red, 1);
    }

    60% {
      width: 40px;
      height: 40px;
    }

    70% {
      opacity: .8;
      width: 40px;
      height: 40px;
      border: 1px solid rgba(red, 1);
    }

    75%,
    100% {
      opacity: 0;
      border: 1px solid rgba(red, 1);
    }
  }

  .fb-wv-1-a {
    animation: fb1 599ms ease forwards;
  }

  // Button content
  .button-content {
    background: transparent;
    width: 100px;
    height: 30px;
    position: absolute;
    z-index: 6;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;

    a {
      width: 50%;
      height: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    .icon {
      width: 19px;
      height: 19px;
      font-size: 1.1em;
      color: #fff;
    }
  }

  .i-1 {
    margin-right: 30px;
  }

  .i-2 {
    margin-left: 30px;
  }
}