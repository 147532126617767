// For mixin functions
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @-ms-keyframes #{$animation-name} {
        @content;
    }

    @-o-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@include keyframes(slide-down) {

    0% {
        background-size: 100% auto;
        background-position: 100% 10%;
    }

    25% {
        background-size: 150% auto;
        background-position: 110% 10%;
    }

    50% {
        background-size: 100% auto;
        background-position: 100% 10%;
    }

    75% {
        background-size: 150% auto;
    }

    // 100% {
    //     background-size: 200% auto;
    // }
}

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
      @content;
    }
  }
  
  @mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
      @content;
    }
  
    @include optional-at-root(':-moz-placeholder') {
      @content;
    }
  
    @include optional-at-root('::-moz-placeholder') {
      @content;
    }
  
    @include optional-at-root(':-ms-input-placeholder') {
      @content;
    }
  }