.__select-product__page {


    .--padding-60 {
        @include size(max, phone, large) {
            padding-bottom: 0 !important;
        }
    }


    .image-slider {
        .img-container {
            .img-wrapper {
                height: 250px;
            }
        }

    }

    @include size(min, phone, large) {
        .image-slider {
            .img-container {
                .img-wrapper {
                    height: 400px;
                }
            }

        }
    }


    .img-container {
        height: 500px;
        pointer-events: all !important;

        &:hover {
            opacity: initial !important;
            transform: unset !important;
        }
    }

    .img-wrapper {
        width: 250px !important;

        button {
            margin-top: 40px;
        }

        .button-container {
            display: flex;
            justify-content: center;
        }
    }

    .type-title {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
    }
}