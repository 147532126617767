.modal-close-wrapper {
	position: fixed;
	z-index: 1998;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	opacity: 0.2;
	background-color: black;
}

.modal-container {
	position: fixed;
	z-index: 1999;
	top: 50%;
	left: 50%;
	//   transform: translate(-50%, -55%);
	width: 420px;
	height: 300px;
	opacity: 1;
	background-color: transparent;
	border-radius: 3px;

	@include size(max, phone, large) {
		padding: 40px;

	}

	.add-warehouse {
		.aszf-link {
			&::before {
				content: unset;
			}
		}
	}
}

.boxed-modal {

	width: 80%;
	max-width: unset;


	tr {
		border-bottom: 1px solid white;
	}

	.boxed--table-type {
		max-width: unset;
	}

	// .cancel-bt {
	// 	margin-right: 100px;
	// }

	.box__img {
		grid-row-start: 1;
		grid-row-end: 2;
		grid-column-start: 1;
		grid-column-end: 2;


		.box__img-wrapper {
			background: rgba(255, 255, 255, 0.31);
			;
			height: 75px;
			border-radius: 5px;
			display: flex;
			width: 100%;
		}

		.icon-bg {

			position: relative;
			width: 31px;
			height: auto;
			margin: 10px auto;

			.box__icon {
				background-position: bottom;
				background-repeat: no-repeat;
				background-size: cover;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
	}

	.numselector-container {
		justify-content: flex-start;
	}

	.table-wrapper {
		width: 100%;
	}

	.total-price {
		display: flex;
		margin-top: 40px;
		margin-bottom: 20px;
		font-size: 20px;
		justify-content: space-around;

		div{
			font-weight: 600;
		}
	}
}

.add-warehouse-modal{


	.__modal-bot{

		
	@include size(max, screen, small) {
		display: none;
	}
	}
}