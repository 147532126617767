$green: #a2ccb6;
$light-peach: #ffecd9;
$peach: #ee786e;
$sand: #fceeb5;
$white: #fff;

.global-button {
	white-space: nowrap;
	display: inline-block;
	text-overflow: ellipsis;

	border-radius: 30px;
	border: none;
	cursor: pointer;
	//	background: color(gradient-main) !important;	
	// background-image: linear-gradient(to right, #895cf2 0%, #ffabf4 50%, #895cf2 100%);

	background-image: color(gradient-main) !important;
	color: #FFFFFF;
	font-size: 14px;
	font-weight: bold;
	padding: 20px 55px !important;
	letter-spacing: 1px;
	text-transform: uppercase;

	transition: all 500ms ease-in-out;
	background-size: 100% auto;


	.indicator-label,
	.indicator-progress {
		line-height: inherit;
	}

	&.grey-background {
		background-image: color(gradient-grey) !important;
	}

	&.black-background {
		background: color(dark-light) !important;
	}

	&:hover {
		@include animation('slide-down 3s 1');
	}



	&.all-item {
		text-align: center;

		&:hover {
			color: white;
			border-radius: 30px;
		}
	}
}

.close-button {
	cursor: pointer;
	background: transparent;
}

.counter-button {
	width: 20px;
	border-radius: 50px;
	height: 19px;

	div {
		margin: auto 0;
	}

	&.bg-main {
		background: color(gradient-main);
	}


}


.ant-btn {
	background: transparent !important;
	border-color: white !important;

	&.action-btn {
		&:hover {
			opacity: .7;
		}

		&.ant-popover-open {
			opacity: .7;
		}
	}
}


.menu-item {

	cursor: pointer;

	&:hover {
		background-color: #dddddd;
		transition: color .2s ease;

		.menu-list-bt {
			color: black;
			font-weight: 600 !important;
		}
	}
}

.menu-link {
	color: black;
}

.menu-list-bt {
	color: black;

	&:hover {
		color: black;
		font-weight: 600 !important;
	}
}

.copy-button {
	button {
		background: #348AA7;
		padding: 6px 15px;
		font-size: 12px;

		svg {
			margin-bottom: 3px;
		}
	}
}

.indicator-progress {
	position: relative;
}

.spinner-border {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto 0;
}

.svg-icon {
	flex-shrink: 0;
	line-height: 0;
	margin-right: 0.5rem;
}

.eye-btn-wrapper {
	position: absolute;
	color: white;
	right: 16px;
    top: 3px;


	.btn {
		border: unset;

		&:hover {
			border: unset;
		}
	}

	.bi {
		color: white;
		font-size: 1.2rem;
	}
}