//Header




//Abstract input
input {
    @include placeholder {
        font-size: 14px;
    }

    &.__center-placeholder {
        @include placeholder {
            text-align: center;
        }
    }
}

.error__item{
    margin-top: 15px;
    color: white;
    list-style: none;
    line-height: 15px;
    // font-weight: 600;
}








.cutted-corner {

    position: relative !important;
    background: transparent;
    width: 100%;
    margin: 0 auto;
    // overflow: hidden;
    padding: 20px;
    z-index: auto;
    // text-align: center;

    background: linear-gradient(-135deg, transparent 20px, #1D1F26 20px) !important;
    // &::after {
    //     content: '';
    //     background: #1D1F26;
    //     position: absolute;
    //     width: 1100%;
    //     height: 1100%;
    //     top: 0px;
    //     right: -500%;
    //     /* background: rgba(255, 255, 255, 0.8); */
    //     -webkit-transform-origin: 54% 0;
    //     -ms-transform-origin: 54% 0;
    //     -o-transform-origin: 54% 0;
    //     transform-origin: 54% 0;
    //     -webkit-transform: rotate(45deg);
    //     -ms-transform: rotate(45deg);
    //     -o-transform: rotate(45deg);
    //     transform: rotate(45deg);
    //     z-index: -1;
    // }


    &.confirmed-order-corner {
        max-height: 80vh;

        &.cart-page-corner {
            max-height: unset;
        }
    }
}

//sticky-cart-menu
.header-imgs-wrapper {
    display: block;
    transition: all .3s linear;
    width: 50px;
    z-index: 1000;

    // position: relative;

    &.right-icon {
        margin-left: auto;
    }

    &.left-icon {


        .logo__icon {
            background: transparent;
            position: relative;
            color: #fff;
            text-align: center;
            font-size: 30px;
            line-height: 60px;
            border-radius: 50%;
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            display: block;
            width: 60px;
            height: 60px;
            transform: none;

            @include size(max, tablet, small) {
                width: 40px;
                height: 40px;
            }

            &:after {
                background-image: url("../../../../_clientPage/assets/images/logo/logo.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 100%;
                height: 100%;
            }
        }
    }


    .cart-menu {

        .screen-lg {
            @include size(max, tablet, large) {
                display: none !important;
            }
        }


        @include size(max, tablet, large) {
            width: 30px !important;
            height: 30px !important;
            z-index: 1000;

            .screen-pay__flow {

                position: absolute !important;
                top: 40px;

                &.cart__icon {
                    ::after {
                        max-width: 20px !important;
                        max-height: 20px !important;
                    }
                }

                .badge {
                    top: 22px;
                    font-size: 12px;
                }
            }
        }

        .cart__icon {
            color: #fff;
            text-align: center;
            font-size: 30px;
            line-height: 60px;
            border-radius: 50%;
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            position: relative;
            display: block;
            width: 60px;
            height: 60px;
            background: color(gradient-main);
            transform: none;
            z-index: 1000;

            &.--fixed {
                position: fixed;
                top: 40px;
                right: 40px;
            }




            &::after {
                background-image: url(../../../../_clientPage/assets/images/graphs/cart-menu.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 100%;
                height: 100%;
                max-height: 30px;
                max-width: 30px;
            }

            &.cart__icon__mobile {

                .badge {
                    font-size: 12px;
                }

                @include size(max, tablet, large) {
                    width: 40px;
                    height: 40px;

                    .badge {
                        top: 26px;
                    }

                    &::after {

                        max-height: 20px;

                    }
                }


            }
        }
    }
}

//map-wrapper
.map-wrapper {
    max-width: 400px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .map {
        background-image: url("../../../../_clientPage/assets/images/backgrounds/map.svg");
        background-position: bottom;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        width: 100%;
        -webkit-background-size: 100% 100%;
        background-size: 100%;
        height: 100%;

        @include size(max, tablet, small) {
            background-position: center;
            height: 325px;
        }

    }
}


//Red vertical abstract svg item 
.red-line-vertical-top {
    background-image: url("../../../../_clientPage/assets/images/graphs/red-line-top.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    position: absolute;
    top: -200px;
    width: 10px;
    -webkit-background-size: 100% 100%;
    background-size: 100%;
    height: 200px;
    left: -1px;

    display: none;

    @include size(max, screen, small) {
        display: block;
    }
}

//Horizontal abstract svg
.red-line {
    background-position: bottom;
    background-repeat: no-repeat;

    position: absolute;
    height: 1px;
    left: 1px;
    right: 10px;
    top: -2px;
    background: linear-gradient(270deg, #E50050 0.02%, #E50050 1.99%, #E0004E 10.68%, #DC004D 23.37%, #63041C 35.41%, #69051E 41.45%, #780723 50.11%, #8E0A28 60.34%, #AC0A2D 71.79%, #D2062F 84.09%, #E4032E 89.48%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    display: none;

    @include size(min, screen, small) {
        display: block;
    }
}

.form_ver-red-wrapper {
    width: 100%;
    position: relative;


    &.joined-user-modal{
        .table-wrapper{
            width: 100%;
        }


        th{
            opacity: .7;       
        }

        td{
            &.passive-status{
                color:#E50050;
            }
            &.active-status{
                color:green;
            }
        }
    }
    &.edit-warehouse-modal{

        .__modal-bot{
            @include size(max, screen, small) {
                display: none;
            }
        } 
    }
}

.plus {
    aspect-ratio: 1/1;
    background:
        conic-gradient(from 90deg at var(--b) var(--b), #000 90deg, transparent 0) calc(100% + var(--b)/2) calc(100% + var(--b)/2)/ calc(50% + var(--b)) calc(50% + var(--b));
    display: inline-block;
    --b: 2px;
    width: 10px;
    margin-bottom: 3px;
    margin-right: 6px;
    opacity: .6;
    pointer-events: none;
}

.minus {
    position: relative;
    width: 20px;
    height: 20px;
    margin: 50px auto 0;
    pointer-events: none;
    cursor: pointer;

    &:after {
        transform: rotate(90deg);
    }


    &:before,
    &:after {
        content: '';
        position: absolute;
        background: white;
        transition: 150ms all ease-out;
    }

    &:before {
        top: 9px;
        left: 5px;
        height: 2px;
        width: 10px;
    }
}


.alt {
    border-color: #fff;
    background:
        conic-gradient(from 90deg at var(--b) var(--b), transparent 90deg, #000 0) calc(100% + var(--b)/2) calc(100% + var(--b)/2)/ calc(50% + var(--b)) calc(50% + var(--b));
}

.alt-white {
    border-color: #fff;
    background:
        conic-gradient(from 90deg at var(--b) var(--b), transparent 90deg, white 0) calc(100% + var(--b)/2) calc(100% + var(--b)/2)/ calc(50% + var(--b)) calc(50% + var(--b));
    opacity: 1;
}

.custom-tooltip {
    position: relative;

    &:before {
        content: "Please contact our support if you whish to change VAT nr.";
        /* here's the magic */
        position: absolute;

        /* vertically center */
        top: 50%;
        transform: translateY(-50%);

        /* move to right */
        left: 100%;
        margin-left: 15px;
        /* and add a small left margin */

        /* basic styles */
        width: 50%;
        padding: 10px;
        border-radius: 10px;
        background: #333333;
        color: #fff;
        text-align: center;
        z-index: 1;
        display: none;
        /* hide by default */

        @include size(max, phone, xlarge) {
            left: 35%;
            top: 265%;
            width: 120px;
            z-index: 1;
        }

    }

    &:after {
        content: "";
        position: absolute;

        /* position tooltip correctly */
        left: 100%;
        margin-left: -5px;

        /* vertically center */
        top: 50%;
        transform: translateY(-50%);

        /* the arrow */
        border: 10px solid #333333;
        border-color: transparent #333333 transparent transparent;

        display: none;

        @include size(max, phone, xlarge) {
            left: 69%;
            top: 102%;
            border-color: transparent transparent #333333 transparent;
        }
    }

    &:hover {

        &:before,
        &:after {
            display: block;
        }
    }
}

.confirmed-bot__wrapper {
    position: absolute;
    width: 200px;
    height: 200px;
    left: -190px;
    bottom: 30px;
    z-index: 0;


    @include size(max, screen, small) {
        left: 30px;
        bottom: unset;
        top: -225px;
    }

    &.__top {

        @include size(min, screen, small) {
            left: -206px;
            top: 30px;
        }
    }

    &.__modal-bot {
        position: absolute;
        width: 160px;
        height: 190px;
        left: -170px;
        bottom: unset;
        top: 30px;
        z-index: 0;

        @include size(max, screen, small) {
            left: 30px;
            bottom: unset;
            top: -195px;
        }

    }

    &.__modal-bot__account {
        width: 132px;
        height: 190px;
        position: absolute;
        left: -153px;
        bottom: unset;
        top: 30px;
        z-index: 0;

        @include size(max, tablet, medium) {
            // left: 30px;
            // bottom: unset;
            // top: -195px;
            display: none;
        }
    }

    .bot-confirmed {
        background-image: url("../../../../_clientPage/assets/images/graphs/bot-confirmed.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    .bot-modal {
        background-image: url("../../../../_clientPage/assets/images/graphs/bot-modal.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    .bot-account {
        background-image: url("../../../../_clientPage/assets/images/graphs/bot-account.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
}

.badge {
    display: inline-block;
    min-width: 1em;
    padding: 0.3em;
    border-radius: 35%;
    font-size: 15px;
    text-align: center;
    background: #ffffff;
    color: red;
    position: absolute;
    top: 40px;
    z-index: 10;
}

.badge-light-success {

    .success-text {
        color: green;

        &.registration-success{
            margin: 20px 0;
        }
    }
}

.badge-light-error {
    .error-text {
        color: red;
    }

    li{
        color: red;
        opacity: .7;
        line-height: normal;
    }
}