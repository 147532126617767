.image-slider {
    .img-container {
        cursor: pointer !important;
    }


    .carousel {
        // margin: 0 60px;
    }


    .item-status {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
    }


}