@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$sidebar-bg-color: #0c1e35;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: #b3b8d4;
$highlight-color: #dee2ec;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import '~react-pro-sidebar/dist/scss/styles.scss';

body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: 'Roboto', sans-serif;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .sidebar-btn {
      transition: width 0.3s;
      width: 150px;
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;

      span {
        margin-left: 5px;
        font-size: 13px;
      }

      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100vw;

    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;

      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      //   text-align: center;

      a {
        text-decoration: none;
        color: #888;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .social-bagdes {
    margin-top: 10px;

    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;

    >span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }

    .block {
      direction: rtl;

      >span {
        margin-left: 0;
        margin-right: 10px;
      }
    }

    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;

    header {
      h1 {
        font-size: 24px;
      }
    }
  }

  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;

  &.red {
    color: #ffffff;
    background: #d63030;
  }

  &.gray {
    color: #ffffff;
    background: #777777;
  }

  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.pro-sidebar-inner {
  background: white !important;
}

.submenu {

  .pro-item-content {
    color: red;
    text-align: initial;
  }

  // &.warehouse-menu {
  //   ul {
  //     &:not(.select-options){
  //       padding-top: 15px !important;

  //     }
  //   }
  // }
}


.pro-menu-item {
  .pro-item-content {
    text-align: initial;
  }
}


.pro-icon,
.pro-item-content {
  color: #0c1e35
}

.pro-icon-wrapper {
  margin-right: unset !important;
}

.pro-sidebar-header {

  .sideber-header {
    padding: 24px;
    overflow: hidden;
    display: flex;
  }

  .logo-container {
    background-color: transparent;
    background-image: url("../../../../_clientPage/assets/images/logo/logo.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 0;
    width: 29px;
    height: 36px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }

  border-bottom: none !important;
}

.pro-sidebar-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 50px;

  .main-menu {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
}

.pro-sidebar-footer {
  border-top: none !important;
  text-align: initial !important;

  .bot-image {
    background-image: url("../../../../_clientPage/assets/images/graphs/bottom-robot.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    width: 50px;
    height: 50px;
    position: relative;
    transition: width .2s;
    background-color: white;
  }
}

.link-sidebar {
  &::before {
    content: unset !important;
  }
}

.pro-sidebar {

  &.collapsed {
    .section-title--medium {
      opacity: 0;
    }

  }
}

.pro-menu-item {

  .list-item-custom {
    font-size: 18px;
    padding: 0;
    line-height: 40px;
    color: #1D1F26 !important;
    position: relative;

    &:hover {
      font-weight: 600;
    }

    &.list-title {
      font-size: 14px;
    }
  }
}

.pro-menu-item {

  &.active {

    .pro-inner-item {
      &:after {
        content: "";
        background: red;
        position: absolute;
        left: 5px;
        height: 20px;
        width: 3px;
      }

      .list-item-custom {
        font-weight: 800;
      }


    }

  }

}

.popper-inner {
  background-color: white !important;
}

.pro-inner-list-item {
  padding-left: 35px !important;

  ul {
    padding-top: 0 !important;
  }
}

.title-wrapper {
  padding-left: 10px;

}

.pro-arrow-wrapper {
  right: 50px !important;
}

.pro-item-content {
  margin-top: 4px;
}

.pro-sidebar {

  &.collapsed {

    .pro-inner-list-item {
      padding-left: 24px !important;
    }

    .pro-menu-item {

      &.active {

        .pro-inner-item {
          &:after {
            left: -3px;

          }



        }

      }

    }
  }


}

.mobil-menu {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;


  .btn-toggle {
    background-color: transparent;

    svg {
      color: black;
      width: 1.5em;
      height: 1.5em;
    }
  }

  @include size(min, tablet, medium) {
    display: none;
  }

  .logo-container {
    background-image: url("../../../../_clientPage/assets/images/logo/logo.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 0;
    width: 35px;
    height: 40px;

  }
}

.pro-inner-item {
  padding: 0px 30px 0px 15px !important;
}

.second-sub {
  margin-top: 10px;
}

@include size(min, tablet, medium) {
  .aside-hoverable {
    width: 80px;
    min-width: unset;

    .pro-arrow-wrapper,
    .title-wrapper {
      opacity: 0;
    }



    .open {

      .pro-inner-list-item {
        height: 0 !important;
      }



    }


    .list-item-custom {
      font-size: 0px;
    }

    &:hover {
      width: 270px;

      .open {
        .pro-inner-list-item {
          height: auto !important;
        }

        .list-item-custom {
          font-size: 18px;
        }

      }

      .pro-arrow-wrapper,
      .title-wrapper {
        opacity: 1;
      }

      .active {
        .pro-inner-item {
          &:after {
            background: red !important;
          }
        }
      }
    }


    .active {
      .pro-inner-item {
        &:after {
          background: unset;
        }
      }
    }


  }

  .aside-hoverable {
    @extend .modal-is-open !optional;
    width: 80px;
  }

  .open {
    .pro-inner-list-item {
      @extend .modal-is-open !optional;
      opacity: 1;
      height: auto !important;
    }
  }

  .pro-arrow-wrapper,
  .title-wrapper {
    @extend .modal-is-open !optional;
    opacity: 1;
  }

}