.webshop-icon {
    background-image: url(../../../../_clientPage/assets/images/icons/black-cart.svg);
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100%;
    left: 0;
    top: 0;
    width: 23px;
    height: 22px;
    display: inline-table;

}

.warehouse-icon {
    // background-image: url(../../../_clientPage/assets/images/icons/black-warehouse.svg);
    background-image: url(../../../../_clientPage/assets/images/icons/black-warehouse.svg);
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100%;
    left: 0;
    top: 0;
    width: 23px;
    height: 22px;
    display: inline-table;

}