@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");

$secondaryFont: "Open Sans",
"light";

// Vars
$fontsize-gigantic: 100px;
$fontsize-huge: 64px;
$fontsize-big: 32px;
$fontsize-medium: 26px;
$fontsize-moderate-x: 24px;
$fontsize-moderate: 22px;
$fontsize-small: 16px;
$fontsize-smaller: 12px;
$fontsize-tiny: 10px;
$fontsize-footer-small: 15px;
$fontsize-footer-big: 18px;


/*Basic Typo fromatting*/

html,
body,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
form,
textarea,
div,
span {
  color: white;


  &.red-text {
    color: color(redNormal);
  }

  &.dark-light-text {
    color: color(dark-light);
  }
}

a {
  color: white;
  text-decoration: none;
  opacity: .9;
  cursor: pointer;
}


i{
  font-style: normal;
}
a:hover,
a:active,
a:focus {
  opacity: 1;
}

a:hover{
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $secondaryFont;
  letter-spacing: 1px;
}

body,
h5,
p {
  font-family: $secondaryFont;
}

h1 {
  font-weight: 900;
  font-size: $fontsize-huge;
}

h2 {
  font-weight: 900;
  font-size: $fontsize-medium;
}

h3 {
  font-weight: 500;
  font-size: $fontsize-moderate;
}

h4 {
  font-weight: 300;
  font-size: $fontsize-small;
}

h5 {
  font-weight: 700;
  font-size: $fontsize-small;
}

p {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;

  &.section-paragraph {
    font-size: 20px;
    font-weight: 300;
    line-height: 20px;
  }

  &.description-text{
    font-size: 12px;
  }
}

.description-link{
  text-decoration: underline;

  &:hover{
    text-decoration: underline;
    color: #0a58ca;
  }
}

/*Typo variants*/

.section-title {
  font-family: $secondaryFont;
  font-size: $fontsize-huge;

  &--huge {
    font-family: $secondaryFont;
    font-size: $fontsize-gigantic;
  }

  &--big {
    font-family: $secondaryFont;
    font-size: $fontsize-medium;
  }

  &--medium {
    font-family: $secondaryFont;
    font-size: $fontsize-medium;
    letter-spacing: 1px;

    &._bold{
      font-weight: 900;
    }
   
  }

  &--small {
    font-family: $secondaryFont;
    font-size: $fontsize-small;
  }
  &._center {
    @include size(max, tablet, large) {
      text-align: center;
    }
  }

}

.weight {

  &--light {
    font-weight: 300;
  }

  &--normal {
    font-weight: 400;
  }
}

.section-subtitle {
  font-family: $secondaryFont;
  font-size: $fontsize-moderate-x;

  &--small {
    font-family: $secondaryFont;
    font-size: $fontsize-smaller;
  }
}

p {
  &.bodytext {
    color: color(dark);
    font-size: $fontsize-small;

    &--big {
      color: color(dark);
      font-size: $fontsize-moderate;
    }
  }
}

.item-title {
  color: color(dark);
  font-size: 18px;
  text-align: center;
}

.item-price {
  color: red;
  font-weight: 700;
  text-align: center;
}

button{
  cursor: pointer;
}

.paragh-title{
font-size: 26px;
margin-bottom: 20px;
}

.empty-title{
  text-align: center !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    font-weight: 600 !important;
}