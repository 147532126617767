.category-items-page {



    .item-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 361px;
        .item-img {
            width: 150px;
            margin-right: auto;
            margin-left: auto;
        }
    }

    .button-container {
        margin-top: 50px;
        display: flex;
        justify-content: center;
    }
}