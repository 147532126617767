.warehouse-confirmed-page {
  

    .route-wrapper{
        @include size(max, screen, small) {
            margin-top: 0 !important;
        }
    }
        .box-wrapper {
            margin-left: auto;
            max-width: 800px;
            position: relative;
            margin-right: auto;
            position: relative;
        }

        .boxed-modal{
            .box-wrapper {        
                max-width: unset;
            }
        }
    .map-wrapper {
        max-width: 500px;
        height: 400px;
        @include size(max, tablet, small) {
            height: 220px;
        }
        .borsod-wrapper {
            width: 200px;
            height: 200px;
            position: absolute;
            top: 0;
            right: 70px;
            @include size(max, tablet, small) {
                display: none;
            }
        }
        .borsod-prototype {
            background-image: url("../../../../_clientPage/assets/images/graphs/borsod.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
        }
    }
    .show_customers-text {
        line-height: 26px;
        margin-left: 10px;
        color: color(dark-light);
        font-weight: 400;
    }
    .switch-input {
        top: 100px;
        display: flex;
        position: absolute;
        top: 50px;
        @include size(min, tablet, large) {
            left: -80px;
            top: 100px;
        }
    }
    .bottom-table {
        margin-top: 50px;
        .table-title{
            margin-bottom: 30px;
        }
        @include size(max, screen, medium) {
            margin-top: 300px;
        }
    }
 
    .confirmed-bot__wrapper {
        @include size(max, screen, medium) {
            left: 30px;
            bottom: unset;
            top: -225px;
        }
    }
}