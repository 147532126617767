.ant-slider{

  &.slider-quantity{
    max-width: 210px;
    margin-left: auto;
    margin-right: auto;

    .ant-tooltip-open,.ant-slider-dot-active {
        border-color: #AC0A2D;
    }

    .ant-slider-track{
        background-color: unset;
        background: linear-gradient(90deg, #E50050 0.02%, #E50050 2.22%, #E0004E 11.94%, #DC004D 26.12%, #63041C 39.58%, #69051E 46.33%, #780723 56.01%, #8E0A28 67.45%, #AC0A2D 80.25%, #D2062F 94%, #E4032E 100.02%);
      }
  }  

 
}