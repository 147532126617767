.__select-type__page {


    @include size(max, phone, large) {

        .page-content {
            padding: 0;
        }

        .type-title {
            font-size: 18px;
        }

    }



    .wout__second-row {

        @include size(min, screen, large) {
            padding-left: 0;
            width:1400px;
        }

    }

    .type-title {
        display: flex;
        align-items: center;
    }

    // @include size(min, screen, large) {
    //     .selected {
    //         .img-container {
    //             transform: unset;
    //             opacity: 1;
    //             border: unset !important;
    //             box-shadow: 1px 4px 13px rgba(0, 0, 0, 0.11);

    //         }
    //     }
    // }

    // .img-container {
    //     opacity: 0 !important;


    // }

    .img-wrapper {
        display: flex;
        justify-content: center;
        padding: 20px;
    }

    @include size(max, tablet, large) {
        .img-wrapper {
            img {
                display: none !important;
            }

        }
    }

    .is-selected {
        .img-container {
            transform: scale(.97);
            opacity: 1 !important;
            border: 1px solid #E4072F;
            box-shadow: 1px 4px 13px rgba(0, 0, 0, 0.11);

        }
    }


}