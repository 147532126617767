.select-products-page {



    .page-content {
        @include size(max, tablet, small) {
            width: unset !important;
        }
    }

    .form-container {

        @include size(min, screen, small) {
            justify-content: start;
        }

        .form_ver-red-wrapper {
            width: unset;
        }
    }

    .normal {

        .page-content {
            width: calc(100vw - 90px);

            @include size(max, tablet, small) {
                width: unset;
            }
        }
    }

    .collapsed {
        .page-content {
            width: calc(100vw - 90px);
        }
    }

    .boxed--table-type {
        max-width: 900px;
    }

    .pro-sidebar {
        height: 100vh;

        position: fixed;

        .pro-sidebar-layout {
            overflow: hidden;
        }


    }


    .table-title {
        padding-left: 3px;
    }

    .documents-table {
        padding: 30px;
    }

    .download-button {
        opacity: .8;

        &:hover {
            opacity: 1;
        }
    }

    @include size(min, tablet, medium) {
        .download-button-wrapper {
            display: flex;
            justify-content: flex-end;
        }
    }

    @include size(max, tablet, medium) {

        td:nth-of-type(1):before {
            content: "Name";
        }

        td:nth-of-type(2):before {
            content: "Language";
        }

        td:nth-of-type(3):before {
            content: "Size";
        }

        td:nth-of-type(4):before {
            content: "Uploaded Date";
        }

        td:nth-of-type(5):before {
            content: "Download";
        }
    }

    tbody td {
        padding: 12px 5px;
    }



    .download-header {
        text-align: end;
    }

    .confirmed-tableRow__item {
        .download-button {
            background-color: transparent;
        }
    }

    .description-wrapper {

        margin-top: 30px;
        margin-bottom: 50px;

        &.first-paragraph {
            margin-top: 120px;
            margin-bottom: 0;
        }

        max-width: 800px;

        padding-left: 0;
        padding-right: 0;

        .item-description {
            font-size: 16px;
            font-weight: 300;
            line-height: 1.4285em;
            text-align: justify;
            color: black;
        }

        hr {
            margin-top: 10px;
            margin-bottom: 10px;
            color: red;
        }

        :not(:first-child) {
            margin-top: 10px;
        }
    }

    .box-wrapper {
        margin-top: 40px;

        &:last-child {
            margin-bottom: 40px;
        }
    }

    .button-container {
        display: flex;
        justify-content: flex-end;

        @include size(max, phone, xlarge) {
            justify-content: center;

            button {
                padding: 20px !important;
            }

        }


    }

    form {

        @include size(min, screen, small) {
            width: 400px !important;
        }

        @include size(min, screen, medium) {
            width: 500px;
        }


        h3 {
            text-align: center;
        }

        padding: 30px;

    }

    .cols-wrapper {
        margin-top: 100px;
        justify-content: center;

        .overlay-col {
            margin: 0;
        }
    }

    .form-container {
        // margin-right: auto;
        padding-right: 0;

        .signin-corner {
            margin-right: unset;
        }

        @include size(max, screen, small) {
            margin-bottom: 50px;
            // margin-top: 50px;
        }
    }

    .overlay-container {
        justify-content: center;

        .product-wrapper {
            margin-bottom: 40px;
        }

    }

    .input__wrapper {
        margin: 30px 0;
        justify-content: center;

     
    }

    .auth-input {

        justify-content: center;

        label {
            // top: -12px;
            font-weight: 300;
        }


        .counterResult {
            padding: 0;
            text-align: center;
        }

        &.counter-btns {

            margin-bottom: 0 !important;
            justify-content: center;
            display: flex;
            margin-left: 5px;


            .auth-input {
                margin-left: 10px;
                margin-right: 10px;
            }

            @include size(max, phone, xlarge) {
                margin-left: auto;
                margin-right: auto;
                width: 100%;

                .counter-button {

                    .minus,
                    .plus {
                        margin-bottom: auto;
                    }
                }
            }


            button {
                display: flex;
                justify-content: center;
                width: 30px;
                height: 29px;

                & .plus,
                .minus {
                    margin-right: 0 !important;
                }
            }
        }

    }

}