table {
	height: auto;
	width: 100%;
	border-collapse: collapse;
}

.name-td,
.name-th {
	padding-left: 40px;
}

.th-end {
	text-align: end;
	padding-right: 10px;
}

.td-end {
	text-align: end;
}



.confirmed-tableRow__item {

	@include size(min, tablet, xlarge) {
		&:nth-of-type(even) {
			background-color: #0f0f0f;
		}
	}

	@include size(max, tablet, xlarge) {
		&:not(:last-of-type) {
			border-bottom: 1px solid white;
		}

		margin-bottom: 10px;
	}



	.date-item {
		font-weight: 600;
	}
}


th {
	color: white;
	font-weight: bold;
}

td,
th {
	padding: 12px 5px;
	line-height: 20px;
	text-align: left;
}

tbody {

	td,
	th {
		padding: 12px 5px;

	}

	td {
		padding: 12px 5px;
	}
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {

	/* Force table to not be like tables anymore */
	table,
	thead,
	tbody,
	th,
	td,
	tr {
		display: block;
	}

	.date-item {
		font-weight: 400 !important;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	// tr { border: 1px solid #ccc; }

	td {
		/* Behave  like a "row" */
		border: none;
		// border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%;
		display: flex;
		justify-content: flex-end;
	}

	td:before {
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		text-align: left;
		left: 0px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
		font-weight: 700;
	}

	/*
	Label the data
	*/
	td:nth-of-type(1):before {
		content: "Order created";
	}

	td:nth-of-type(2):before {
		content: "NET amount";
	}

	td:nth-of-type(3):before {
		content: "Order status";
	}

	td:nth-of-type(4):before {
		content: "Delivery status";
	}

	td:nth-of-type(5):before {
		content: "Payment status";
	}

	.joined-user-modal {
		td:before {
			color: white;
			opacity: .7;
		}

		td:nth-of-type(1):before {

			content: "Name: ";
		}

		td:nth-of-type(2):before {
			content: "Status: ";
		}

		td:nth-of-type(3):before {
			content: "Coins: ";
		}
	}

	.addresses-page {
		td:nth-of-type(1):before {
			content: "Name";
		}

		td:nth-of-type(2):before {
			content: "Phone";
		}

		td:nth-of-type(3):before {
			content: "City";
		}

		td:nth-of-type(4):before {
			content: "Address";
		}

		td:nth-of-type(5):before {
			content: "";
		}
	}
}