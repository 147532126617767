.custom-select-container {
    display: inline-block;
    width: 100%;
    text-align: center;
    position: relative;
    cursor: pointer;


    .query-input{
        margin: 0;
    border: 1px solid #CCC;
    line-height: 20px;
    transition: all .2s ease-in-out;
    color: white;
    opacity: .9;
    font-size: 14px;
    padding: 10px;
    }
    .selected-text {
        height: 39px;
        background-color: color(dark-light);
        font-size: 14px;
        text-align: left;
        padding: 6px 12px;
        line-height: 1.82857143;
        margin-right: 0 !important;
    }

    .selected-text::after {
        content: '';
        display: inline-block;
        float: right;
        width: 8px;
        height: 8px;
        border-bottom: 2px solid red;
        border-left: 2px solid red;
        border-bottom-left-radius: 2px;
        transform: rotate(-45deg) translate(0%, 50%);
        transform-origin: center center;
        transition: transform ease-in-out 100ms;
        margin-top: 4px;
        margin-right: 5px;
    }

    .selected-text.active::after {
        transform: rotate(45deg) translate(100%, 0%);
    }

    ul {
        padding: 0;
        text-align: center;
        z-index: 1;
        width: 100%;
        background: #f2f2f2;
        position: absolute;
        top: calc(100% + .5rem);
        left: -1px;
        margin: 0;
        box-sizing: border-box;
        max-height: 200px;
        overflow-y: auto;
        border: 1px solid #CCC;
    }

    // .select-options {
    //     position: absolute;
    //     width: 100%;
    // }
    .select-options {
        z-index: 2;
    }

    option {
        padding: 0;
        text-align: center;
        z-index: 1;
        width: 100%;
        background: #f2f2f2;
        position: absolute;
        top: calc(100% + .5rem);
        left: -1px;
        margin: 0;
        box-sizing: border-box;
        max-height: 200px;
        overflow-y: auto;
        border: 1px solid #CCC;
        font-size: 18px;
    }

    select {
        list-style-type: none;
        background-color: color(dark-light);
        cursor: pointer;
        margin: 0;
        // padding: 1rem 0;
        border-bottom: 1px solid #CCC;
        line-height: 20px;
        transition: all .2s ease-in-out;
        color: white;
        opacity: .9;
        font-size: 14px;
        padding: 10px;
    }

    li {
        list-style-type: none;
        background-color: color(dark-light);
        cursor: pointer;
        margin: 0;
        // padding: 1rem 0;
        border-bottom: 1px solid #CCC;
        line-height: 20px;
        transition: all .2s ease-in-out;
        color: white;
        opacity: .9;
        font-size: 14px;
        padding: 10px;
    }

    li:hover {
        background-color: #f3f3f4;
        color: color(dark-light)
    }

    .active-option{
        background-color: #f3f3f4;
        color: color(dark-light)
    }

    .default-select {
        color: var(--kt-input-placeholder-color) !important;
    }
}