.swiper {
    // width: 450px;
    height: 100%;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
    list-style: none;

    &.type-1{
        height: 450px;
    }

    &.type-2{
        height: 250px;
    }
}

.swiper-container-pointer-events {
    touch-action: pan-y;
}

.swiper-slide {
    flex-shrink: 0;
    width: 100px;
    background-color: transparent;
    position: relative;
    transition-property: transform;
    display: flex;
    justify-content: center;
    align-items: center;

    &.type-1{
        height: 450px;
    }

    &.type-2{
        height: 250px;
    }
}


.button {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.swiper-arrow-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #252525;
}


.swiper-arrow-button:not(:hover) {
    opacity: .5;
}

.swiper-arrow-button:after {
    font-size: 14px;
    color: white;
}

.swiper-slide-active {
    .img-container {

        &.type-2{
            transform: scale(.97);
            opacity: 1;
            border: 1px solid #E4072F;
            box-shadow: 1px 4px 13px rgba(0, 0, 0, 0.11);
        }
       
    }
}

.img-container {

    &.type-2{
        pointer-events: none;
        background: white;
        width: 420px;
        height: 250px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        transition: all 0.3s;
        cursor: grab;
        align-items: center;
        opacity: .5;
        transform: scale(.8);
        border-radius: 10px;
        box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.11);

        @include size(max, tablet, small) {
            width: 250px;
            height: 164px;

            img {
                width: 50%;
            }
        }



        &:hover {
            opacity: 1;
            transform: scale(.95);
        }

        img {
            display: block;
            margin: auto;
            width: 30%;
        }

        h3 {
            color: black;
            font-weight: 300;
        }
    }

    &.type-1{

        pointer-events: all;
        width: 350px;
        height: 450px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        transition: all 0.3s;
        cursor: grab;
        align-items: center;
        border-radius: 10px;

        @include size(max, phone, large) {
            // width: 150px;
            // height: 250px;
            height: 350px;

            img {
                height: 150px;
            }
        }



        // &:hover {
        //     opacity: 1;
        //     transform: scale(.95);
        // }

        img {}

        h3 {
            color: black;
        }


        .item-title {
            color: #000;
            font-size: 18px;
            font-weight: 600;
            opacity: .7;
        }

        .item-price {
            color: red;
            font-weight: 700;
            font-size: 20px;
        }

        .item-title,
        .item-price {
            @include size(max, tablet, small) {
                font-size: 14px;
            }
        }

        .img-wrapper {
            width: 350px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            height: 85%;
            .button-container {
                display: flex;
                justify-content: center;
            }

            img {
                height: 200px;
                margin-bottom: 30px;
                margin-right: auto;
                margin-left: auto;
            }


            @include size(max, phone, large) {
                // width: 150px;
                // height: 250px;
                height: 300px;

                img {
                    height: 100px;
                }
            }
        }


    }


    }

    .swiper-slide-prev,.swiper-slide-next{
        .button{
            cursor: pointer;
        }

    }

    .slide-title{
        position: absolute;
        bottom: 20px;
        color: grey;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

  