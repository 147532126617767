.errors-layout-page {
    background: #1d1f26;

    .homepage-btn-wrapper {
        padding: 40px 0;
    }

    h1 {
        font-size: 44px;
    }

    .robot-error-wrapper {
        width: 350px;
        margin-left: auto;
        margin-right: auto;
    }

    .error-image {
        min-height: 350px !important;
        background-image: url(../../../../_clientPage/assets/images/backgrounds/17.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: center;
        background-position-y: bottom;
    }

}