  	//
  	// Form controls
  	//

  	.form-control {

  	    // Placeholder
  	    &::placeholder {
  	        color: var(--kt-input-placeholder-color) !important;
  	    }

  	}