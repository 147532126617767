html,
body {
    display: flex;
    width: 100%;
    // flex-direction: column;
    // align-items: stretch;
    // min-height: 100vh;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
    height: unset;

    @include size(min, tablet, large) {
        // overflow: auto !important;
    }
}

#root {
    width: 100%;
    display: flex;
    flex: 1 1;
    flex-direction: column;
}

//auth-pages
.page {
    --bs-bg-opacity: 0 !important;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;

    padding-bottom: 40px;



    @include size(min, screen, small) {
        padding-bottom: 0px;
    }

    .reg-container {
        @include size(max, phone, large) {
            padding: 0px;
        }
    }


}

//webshop-pages
.webshop-pages {
    display: flex;
    width: 100%;
    min-height: 100vh;




    .bottom-table {
        margin-top: 100px;
    }

    .red-line {
        transform: rotateY(180deg);
        bottom: 5px;
        right: unset;
        top: unset;
        width: 500px;

        &.__modal {
            right: 18px;
            top: -21px;
            width: 612px;
            transform: rotateY(0deg);
        }
    }



    .titles-wrapper {



        &.route-wrapper {
            margin-top: 20px;
        }

        .map-route {
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            top: 77px;
            right: 0;
            left: 0;
            text-align: center;
            font-size: 16px;

            span {
                color: red;
            }

            span:last-child {
                color: grey;
            }
        }
    }

    .page-content {

        // &.container{
        //     max-width: unset;
        // }
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: calc(100vw - 300px);





        @include size(max, tablet, large) {
            width: calc(100vw - 0px);
        }

        &:before,
        &:after {
            content: none;
        }

        .page-background {
            background-image: url("../../../../_clientPage/assets/images/backgrounds/Rectangle.webp");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
        }

        .page-title {
            line-height: 70px;
            margin-top: 70px;
            font-size: 40px;
            margin-bottom: 20px;

            &.__with-routemap {
                margin-top: 100px;
            }

            @include size(max, screen, small) {
                font-size: 40px;
                text-align: center;
            }


            @include size(min, tablet, large) {
                font-size: 74px;
            }


            @include size(min, screen, small) {
                margin-left: 50px;
                margin-right: 50px;
            }

            &.__center {

                @include size(min, tablet, large) {
                    text-align: center;
                }


                &.select__product {
                    font-size: 44px;
                    color: white;
                    font-weight: 400;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    @include size(min, tablet, small) {
                        flex-direction: row;
                    }

                    .select__number {
                        letter-spacing: -2px;
                        margin-right: 20px;
                        color: red;
                        font-size: 90px;
                        font-weight: 900;
                    }
                }
            }
        }

        .page-subtitle {

            font-weight: 300;
            color: color(dark-light-s);

            @include size(max, screen, small) {
                text-align: center;

            }

            @include size(min, screen, small) {
                margin-left: 245px;
            }

            @include size(min, tablet, large) {
                margin-top: 20px;
            }


            @include size(max, phone, huge) {
                font-size: 18px;
                font-weight: 500;
            }
        }
    }
}

/*General MAIN CONTAINER*/
.container {
    width: 100%;
    //  max-width: 100% !important;
    padding: 0 12px;
    flex-grow: 1;
    position: relative;
    // max-width: 100%;


    @include size(min, tablet, medium) {
        padding: 0 16px;
    }


}

.overlay-col {
    margin: 50px 0;



    @include size(min, phone, huge) {
        margin: 50px 0 60px 0;
    }

    @include size(min, screen, small) {
        margin-bottom: 150px;
    }

}

.form-col {

    @include size(max, phone, huge) {
        padding: 0;
    }
}

.overlay-container {
    overflow: hidden;
    display: flex;
    align-items: center;

    h1,
    h3 {
        text-align: center;
    }



    .product-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 358px;
        margin-bottom: 10px;


        @include size(max, phone, large) {
            height: 255px;
        }

        .product-container {
            position: relative;
            height: 100%;
            width: 289px;


            @include size(max, phone, large) {
                width: 200px;
            }

            .product {
                background-position: bottom;
                background-repeat: no-repeat;
                background-size: cover;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 280px;

                @include size(max, phone, large) {
                    width: 200px;
                }
            }
        }
    }

    .logo-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 85px;
        margin-bottom: 10px;

        .logo-container {
            position: relative;
            height: 100%;
            width: 80px;


        }

        .logo {
            background-image: url("../../../../_clientPage/assets/images/logo/logo.svg");
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 70px;
        }
    }
}

.bg-image__cover-overlay {
    background-color: rgba(0, 0, 0, .05);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}

.bg-image-container {
    display: block;
    height: 100vh;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    // @include size(min, screen, small) {
    //     height: 100vh;
    // }

    height: 100%;

    .bg-image {
        background-image: url("../../../../_clientPage/assets/images/backgrounds/bgImage.jpeg");
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }


}

.section {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 120px 0;

    &--auths-boxes {
        width: 100;
        flex-direction: column;

        @include size(min, screen, small) {
            width: 85%;
            flex-direction: row;
        }

        .container {
            width: 100%;

            .cols-wrapper {
                flex-direction: column;

                @include size(min, screen, small) {

                    flex-direction: row;
                }
            }

            .row {
                display: flex;
                align-items: center;
            }


        }
    }


}

.tooltip-icon-wrapper {
    position: relative;

    .tooltip-icon {
        width: 20px;
        border: unset;

        position: absolute;
        top: 7px;
        cursor: pointer;
        right: 10px;
    }
}

.top-bar {
    position: absolute;
    bottom: 0;
}

.lg-padding-section {
    padding: 50px 40px !important;
}

.page-content {
    &.--padding-60 {
        padding: 60px;
        padding-bottom: 0;

        @include size(max, tablet, small) {
            padding: 20px;
            padding-bottom: 100px;
        }
    }
}

.boxed--table-type {
    top: 0;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    background: color(dark-light);
    padding: 20px;

    .item-description {
        margin-bottom: 10px;
        width: 100%;
    }

    td {
        button {
            background: transparent;
        }
    }

    h3 {
        width: 100%;
        font-weight: 300;
    }

    .boxed-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: stretch;

        h3 {
            text-align: left;
        }

        .full-screen-bt {
            background: transparent;

            svg {
                height: 1.7em;
                width: 2em;
                margin-right: 15px;
            }
        }




    }
}

.page-content-wrapper__two-rows-sp__around {
    height: 100%;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    padding: 50px 0;

    @include size(min, tablet, large) {
        padding: 50px;
    }





    .first-row__wrapper {
        display: flex;
        justify-content: center;
    }

    .second-row__wrapper {
        display: flex;
        margin-top: 80px;

        @include size(max, tablet, large) {
            flex-direction: column;
        }


        .browse-items {
            display: flex;
            align-items: center;


            @include size(max, tablet, large) {
                justify-content: center;
            }

            @include size(min, tablet, large) {
                margin-left: 30px;
            }

            .global-button {

                @include size(max, tablet, xxlarge) {
                    width: 302px;
                }

            }
        }
    }

    .section-title {
        font-size: 44px;
        font-weight: 700;
    }

    .page-subtitle {
        @include size(min, tablet, large) {
            margin-left: 105px !important;
        }

    }

    &.wout__second-row {
        justify-content: flex-start;


    }
}




// .page__menus {

//     @include size(max, tablet, large) {
//         flex-direction: column;
//     }
// }

.page-background {
    background-image: url("../../../../_clientPage/assets/images/backgrounds/Rectangle.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.collapsed {

    .page-content {

        @include size(min, tablet, medium) {
            width: calc(100vw - 90px);
        }

    }

}

.opacity-light {
    pointer-events: none;
    opacity: .2;

    div {
        opacity: .2;
    }
}

.cart-row {
    position: absolute;
    top: 40px;
}