// Basic resetting

html, head, body,
header,footer,
nav, menu, details, dialog,
main, section, article, aside,
table, thead, tfoot, tbody, td, tr, th,
form, input, button, textarea, datalist, fieldset,label, legend, meter, option, optgroup, output, progress, select,
code, iframe, embed, data, cite, mark,
source,
svg, script, noscript, canvas,
div, blockquote, dd, dl, dt,
span,
figure, figcaption, caption,
img, area, video,
hr,pre,
ul, ol, li,
h1,h2,h3,h4,h5,h6,
p, a, a:hover, a:visited, a:active, a:focus
b, i, u, strong, br, center{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    line-height: 0; 
    border: 0;
    border-radius: 0;
    border-width: 0;
} 

span, 
p, div, 
h1,h2,h3,h4,h5,h6, 
b, i, i, strong, br, center
{
    line-height: normal;
}