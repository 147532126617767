.box-wrapper {

    overflow-y: initial !important;
    // #discount-code{
    //   &::placeholder {
    //   text-align: center;
    //   font-size: 14px;
    //     }

    // }

    .discount-code-label {
        display: flex;
        align-items: center;
    }

    .group-title {
        display: flex;
        align-items: center;
        font-weight: 400;

        &.brand-title {
            font-size: 18px;
            margin-left: 0;
            margin-top: 10px;
            font-weight: 500;
            opacity: 0.5;
            letter-spacing: 2px;

        }
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 10px 0px;

        @include size(max, tablet, small) {
            margin-top: 30px;
            flex-direction: column;

            .grid-wrapper {
                .row {



                    margin-bottom: 10px;


                    &::after,
                    &::before {
                        content: unset;
                    }

                    justify-content: space-between;
                }
            }
        }

        .heade-title {
            font-weight: 800;
            margin-right: 5px;
        }

        .grid-wrapper {

            .container-fluid {
                padding-left: 14px;
                padding-right: 14px;

            }

            .row {
                flex-direction: row;
                display: flex;
            }

            .col {
                font-size: 14px;
                padding-left: unset;
                padding-right: unset;


                &.second {
                    @include size(max, tablet, small) {
                        text-align: end;
                    }
                }
            }
        }
    }

    .table-wrapper {
        overflow-y: auto;
        max-height: 80vh;
    }

    .boxlist {
        width: 100%;

        padding: 20px 0;

        overflow-y: auto;
        height: 800px;


        &.cart-list {
            height: unset;
            padding-bottom: 0;
            padding-top: 20px;
        }

        .display-none-mode {
            display: none !important;
        }

        .box__img {
            width: 100px;
        }

        .boxlist__item {
            display: grid;
            grid-template-rows: 30px auto;

            &:not(:last-child) {
                margin-bottom: 30px;
            }

            padding-bottom: 20px;
            border-bottom: 1px solid gray;


            .boxlist__price {



                font-weight: 300;
            }

            .item__kinds-wrapper {
                // grid-row-start: 2;
                // grid-column-start: 1;
                // grid-column-end: 8;
                // height: max-content;

                // @include size(max, tablet, small) {
                //     grid-row-start: 2;
                //     grid-column-start: 1;
                //     grid-column-end: 8;
                // }

                .kind-item {
                    .attention-row {
                        grid-row-start: 4;
                        grid-column: 1 / span 4;
                    }

                    // &:not(.last-item) {
                    //     padding-bottom: 20px;
                    //     border-bottom: 1px solid gray;
                    // }

                }

                .kind-item {
                    display: grid;
                    margin-top: 10px;
                    grid-template-columns: 22% 28% 28% 22%;


                    // @include size(min, phone, xlarge) {
                    //     grid-template-columns: 50% 50%;
                    // }

                    .item--name,
                    .result__price,
                    .trash-wrapper {
                        display: flex;
                        align-items: center;

                        @include size(max, phone, xlarge) {
                            align-items: left;
                        }
                    }

                    .item--name {
                        grid-row-start: 1;
                        grid-column-start: 1;

                        @include size(max, phone, xlarge) {
                            grid-column-end: 4;
                            margin-top: 10px;
                            width: 90%;
                        }

                        @include size(min, phone, xlarge) {
                            grid-column-end: 4;
                            margin-top: 10px;
                            width: 90%;
                        }

                        h5 {
                            font-size: 14px;
                        }
                    }

                    .result__price {
                        font-size: 16px;
                        text-align: center;
                        grid-row-start: 2;
                        grid-column-start: 2;
                        grid-column-end: 4;
                        font-weight: 600;
                        font-size: 14px;

                        &.__center {
                            justify-content: center;

                        }

                        justify-content: center;


                        @include size(max, phone, xlarge) {
                            text-align: center;
                            margin: 15px 0px;
                        }

                        @include size(min, phone, xlarge) {
                            grid-row-start: 2;
                            margin: 15px 0px;
                        }

                    }


                }


                
            }


            .trash-wrapper {


                .remove-item-btn {
                    opacity: 1;

                    &:hover {
                        opacity: .7;
                    }
                }

                display: flex;
                grid-row-start: 1 !important;

                // @include size(min, phone, xlarge) {
                //     grid-row-start: 1 !important;
                // }

                button {
                    margin-left: auto;
                    margin-right: auto;
                }

                @include size(max, tablet, medium) {
                    display: flex;
                    justify-content: center;
                }

                .trash-container {
                    bottom: 40px;
                    //   background-color: #f3f3f4;
                    padding: 6px;
                    height: 100%;
                    cursor: pointer;

                    @media screen and (max-width:768px) {
                        padding: 0;
                    }
                }
            }

            .svg-inline--fa {


                display: inline-block;
                font-size: inherit;
                height: 1em;
                overflow: visible;
                width: 0.875em;
                vertical-align: -0.125em;

                @media screen and (max-width:768px) {
                    height: 1.5em;
                    width: 1em;
                }
            }

        }


    }

    .boxed__footer {
        border-top: 1px solid gray;
    }

    .summary-container {
        padding: 5px 0px;
        border-bottom: 3px double white;

        >.row {
            margin: 20px 0;
        }
    }

    .total-container {
        margin-top: 20px;
        font-weight: 600;
    }


    .boxed__footer {
        display: flex;
        width: 100%;
        flex-direction: column;



        .second-col {
            text-align: end;
        }

        .discount-input {
            background: transparent;
            border-image: color(gradient-main) 1;
            color: white;


            &:focus {
                box-shadow: unset;
            }

            &::placeholder {
                color: white;
                opacity: 1;
            }
        }

        .col {
            font-size: 16px;
        }

        .row {
            justify-content: space-between;
            flex-direction: row;
            display: flex;

            &:before,
            &:after {
                content: unset;
            }
        }


    }

    .button-wrapper {

        &.orient-cols {
            flex-direction: column;
            align-items: center;

            .global-button {
                margin-bottom: 10px;
            }
        }

    }

    .bot-account {
        height: 203px !important;
        width: 140px !important;
    }



}

.box__quantity {



    @include size(max, phone, xlarge) {
        grid-row-start: 3;
        grid-column: 1 / span 4;
    }

    @include size(min, phone, xlarge) {
        grid-row-start: 3;
        grid-column-start: 2 !important;
        grid-column-end: 4 !important;
    }

    .auth-input {
        max-width: 100px;

        input {
            text-align: center;
            background: #1D1F26;
            border: none;
            opacity: 1;
            color: white;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .numselector-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        margin: 0 auto;



        .form-control {
            @include size(max, phone, xlarge) {
                padding: 0.375rem 0.55rem;
            }
        }

        @include size(max, phone, xlarge) {
            grid-row-start: 3 !important;
        }

        button {
            &:nth-of-type(1) {
                margin-right: 5px;
            }

            &:nth-of-type(2) {
                margin-left: 5px;
            }
        }




        .numselector__number {
            font-weight: 300;
        }

        .numselector__minus,
        .numselector__plus {
            background-color: color(dark-white);
            padding: 6px;
            height: 100%;
            cursor: pointer;


        }

    }
}