@mixin backlight($x, $y, $spread, $size, $colorA, $colorB, $duration) {
    &:after {
        position: absolute;
        content: "";
        top: $y;
        left: $x;
        right: 0;
        z-index: -1;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        transform: scale($size);
        -webkit-filter: blur($spread);
        -moz-filter: blur($spread);
        -ms-filter: blur($spread);
        filter: blur($spread);
        background: linear-gradient(270deg, $colorA, $colorB);
        background-size: 200% 200%;
        animation: animateGlow $duration ease infinite;

        @keyframes animateGlow {
            0% {
                background-position: 0% 50%
            }

            50% {
                background-position: 100% 50%
            }

            100% {
                background-position: 0% 50%
            }
        }
    }
}


.form__light {
    @include backlight(-83px, 0, 6vw, 0.95, #ecb415, #5a4235, 5s);

}

.form-container {
    top: 0;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;



    display: flex;
    align-items: center;

    padding: 20px 10px;



    justify-content: center;


    &.registrations-form {
        @include size(max, phone, xlarge) {
         padding:0;
        }
    }

    @include size(min, screen, small) {
        margin-right: 0;
    }

    form {
        background: transparent;
        width: 100%;
        padding: 40px;
        z-index: 1;

        @include size(min, screen, small) {
            width: 500px;
        }

        @include size(max, phone, large) {
            padding: 40px 20px;
        }

        &.auths-form{
            @include size(max, phone, huge) {
                padding: 40px 20px;


                .selected-text{
                        padding: 6px 6px;             
                }
            }


        }
    }

    @include size(min, phone, huge) {
        padding: 20px;
    }

    .already-account-wrapper {
        a {
            text-decoration: underline;
        }
    }

    form {

        .auth-input {
            &.button-wrapper {
                display: flex;
                align-items: center;
            }


        }

        >div.auth-input {
            &:not(:last-of-type) {
                margin-top: 20px;
            }
        }

        .button-container {
            &.button-mb {
                margin-bottom: 40px;
            }
        }

    }

    h3 {
        color: white;
        font-size: 26px;
        font-weight: 300;
        margin-bottom: 20px;
    }

    select {
        width: 100%;
        background: color(dark-light);
        padding: 12px 15px;
    }

    input {
        padding: 12px 15px;
        color: white;
        background: color(dark-light);
        position: relative;
        width: 100%;

        &::placeholder {
            font-size: 12px;
            font-weight: 300;
            font-style: normal;
            color: white;
            /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            font-size: 12px;
            font-weight: 300;
            font-style: normal;
            color: white;
        }

        &:-ms-input-placeholder {
            /* Microsoft Edge */
            font-size: 12px;
            font-weight: 300;
            font-style: normal;
            color: white;
        }
    }

    .input__wrapper {
        display: flex;
        margin-bottom: 20px;
        flex-direction: column;
        // @include size(max, phone, xlarge) {
        //     div:first-child {
        //         margin-bottom: 20px;
        //     }
        // }

        .attention-wrapper {
            text-align: center;
        }

        .attention-min-number {
            text-align: center;
            opacity: .6;
            font-size: 10px;
        }

        .attention-min-text {
            text-align: center;
            font-size: 10px;
            opacity: .9;
        }
        
        div:first-child {
            margin-right: 10px;

            @include size(max, phone, xlarge) {
                margin-right: 5px;
            }
        }

    }

    .gradient-border {
        position: relative;
        background: color(gradient-main);
        padding: 1px;
        width: 70%;

        &.normal-input {
            width: 100%;
        }

        &.input-50 {
            width: 50%;

            @include size(max, phone, xlarge) {
                width: 100%;
            }

            &.custom-select {
                @include size(max, tablet, small) {
                    width: 100%;
                }
            }
        }

        &.input-80 {
            width: 80%;
        }


        &.input-10 {
            width: 10%;

            @include size(max, phone, xlarge) {
                width: 20%;
            }

        }

        &.input-15 {
            width: 15%;

            @include size(max, phone, xlarge) {
                width: 25%;
            }

        }

        &.input-25 {
            width: 25%;


            @include size(max, phone, xlarge) {
                width: 50%;
            }
        }

    }

    .form-control {
        border-radius: 0;
        border: none;

        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;

        &.quantity-wrapper {
            display: flex;
            padding: 0;
            background-color: #1D1F26;
        }

        &:focus {
            box-shadow: none;
            background-color: color(dark-light);
            color: white;
        }

        &:focus-visible {
            outline: none;
            // -webkit-box-shadow: 2px 0px 16px 1px rgba(255, 8, 8, 0.88);
            // box-shadow: 2px 0px 16px 1px rgba(255, 8, 8, 0.88);
        }
    }

    .contact-container {
        margin-bottom: 15px;

        p {
            font-size: 14px;
            opacity: .9;
        }
    }

    .aszf-container {
        margin-bottom: 15px;
        font-size: 12px;

        a {
            padding-left: 30px;
            line-height: 20px;
        }

        div {
            &.aszf-link {
                position: relative;
                margin-left: 30px;
                line-height: 20px;
            }
        }

    }

    .quantity {
        width: 50%;
    }

    .max-pieces {
        display: flex;
        align-items: center;
        background-color: color(dark-light);
    }
}

.form-control[disabled] {
    // background-color: #bebebe;
    background: #333333;
}

.form-control[readonly] {
    background-color: color(dark-light);
}

.bottom-side {


    p {
        font-weight: 500;
        font-size: 10px;
        text-align: center;
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        div:first-child {
            margin-right: 10px;
        }

        .global-button {

            @include size(max, phone, xlarge) {
                padding: 20px 35px !important;
            }

            &.padding-20 {
                @include size(max, phone, xlarge) {
                    padding: 20px 25px !important;
                }
            }
        }
    }
}

.gradient-border {
    position: relative;
    background: color(gradient-main);
    padding: 1px;
    width: 70%;

    &.normal-input {
        width: 100%;
    }

    &.input-50 {
        width: 50%;

        @include size(max, phone, xlarge) {
            width: 100%;
        }
    }

    &.input-80 {
        width: 80%;
    }


    &.input-10 {
        width: 10%;

        @include size(max, phone, xlarge) {
            width: 20%;
        }

    }

    &.input-15 {
        width: 15%;

        @include size(max, phone, xlarge) {
            width: 25%;
        }

    }

    &.input-25 {
        width: 25%;


        @include size(max, phone, xlarge) {
            width: 50%;
        }
    }

}