.select-brand__page {


    .route-wrapper{
        margin-top: 0 !important;
    }

    @include size(max,tablet,small){
        .slide-basic {
            opacity: 0 !important;
        }
    
        
            .is-selected {     
                    transform: unset;
                    opacity: 1 !important;
                    border: unset !important;
                    box-shadow: 1px 4px 13px rgba(0, 0, 0, 0.11);
            }
    }
  
    

    



    .wout__second-row {

        @include size(min, screen, large) {
            padding-left: 0;
            width: 1300px;
        }

    }


    @include size(max, screen, large) {
        .image-slider {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
    }


    .__with-routemap {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .slide-cards {

       
        .second-row__wrapper {
            justify-content: center !important;
            flex-direction: row;
        }

        .button-wrapper {
            display: flex;
            flex-direction: row;

            div:first-child {
                margin-right: 15px;
            }
        }
    }
}