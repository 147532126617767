.fv-plugins-message-container{
    background-color: #1D1F26;
    width:70%;
    opacity: .9;
    span{
        color: rgb(228, 3, 46);
    }
}

.alert-danger{
    &.custom-danger{
    background-image: linear-gradient(90deg, #E50050 0.02%, #E50050 2.22%, #E0004E 11.94%, #DC004D 26.12%, #63041C 39.58%, #69051E 46.33%, #780723 56.01%, #8E0A28 67.45%, #AC0A2D 80.25%, #D2062F 94%, #E4032E 100.02%) !important;
}
}
